import {
  CreateEventFormData,
  CustomerEvent,
  EditEventFormData,
  UpdateEventData,
} from 'types/event';

import { api } from './client';

export interface CustomerEventsWithPagination {
  data: CustomerEvent[];
  nextStart: string;
}

export const fetchEvents = async (
  start: string
): Promise<CustomerEventsWithPagination> => {
  const {
    data: { data },
  } = await api.get(`/events?start=${start}&limit=10`);

  return {
    data,
    nextStart: data.length < 10 ? undefined : data[data.length - 1].start,
  };
};

export const createEvent = async (
  event: CreateEventFormData
): Promise<CustomerEvent> => {
  const {
    data: { data },
  } = await api.post('/events', event);

  return data;
};

export const deleteEvent = async (id: string): Promise<null> => {
  return await api.delete(`/events/${id}`);
};

export const editEvent = async (
  eventData: EditEventFormData
): Promise<CustomerEvent> => {
  const {
    data: { data },
  } = await api.put(`/events/${eventData.id}`, eventData);

  return data;
};

type PatchEventData = Partial<UpdateEventData>;
export const patchEvent = async (
  id: string,
  eventData: PatchEventData
): Promise<CustomerEvent> => {
  const {
    data: { data },
  } = await api.patch(`/events/${id}`, eventData);

  return data;
};
