import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Languages } from 'enums/Languages.enum';
import { AppRoutes } from 'enums/Routes.enum';
import { LocalStorage } from 'services/LocalStorage';
import {
  STORE_AUTH_TOKEN,
  STORE_LANGUAGE,
  STORE_TENANT_ID,
} from 'utils/constants';

const lang: Languages | null = LocalStorage.getItem(STORE_LANGUAGE);
const token = LocalStorage.getItem(STORE_AUTH_TOKEN);

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Accept-Language': lang || Languages.EN,
    Authorization: `Bearer ${token}`,
  },
});

api.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const requestConfig = config;
    const controller = new AbortController();
    const token = LocalStorage.getItem(STORE_AUTH_TOKEN);

    if (!token) {
      window.location.replace(AppRoutes.Login);
      localStorage.removeItem(STORE_AUTH_TOKEN);
      localStorage.removeItem(STORE_TENANT_ID);
      controller.abort();
    }

    if (token && requestConfig) {
      requestConfig!.headers!.Authorization = `Bearer ${token}`;
    }
    return { signal: controller.signal, ...requestConfig };
  }
);

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error) => {
    const accessToken = LocalStorage.getItem(STORE_AUTH_TOKEN);

    if (!accessToken || error?.response?.status !== 401 || !error.response) {
      return Promise.reject(error);
    }

    window.location.replace(AppRoutes.Login);
    localStorage.removeItem(STORE_AUTH_TOKEN);
    localStorage.removeItem(STORE_TENANT_ID);

    return Promise.reject(error);
  }
);
