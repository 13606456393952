export enum UserScopes {
  Dashboard,
  Administration,
  TenantSwitcher,
  SelectUserTenant,
  SetUserBetaRole,
  UpdateLabels,
  UpdateSensitivity,
  AlertSubscriptions,
}
