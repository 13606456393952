import React, { useState } from 'react';
import {
  Button,
  Callout,
  Popover,
  Badge,
  Flex,
  Heading,
  Skeleton,
  AlertDialog,
} from '@radix-ui/themes';
import {
  BellIcon,
  InfoCircledIcon,
  MinusCircledIcon,
} from '@radix-ui/react-icons';
import { AlertSubscriptionForm } from 'components/AlertSubscriptionForm';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as BellIconFilled } from 'assets/icons/bell-icon-filled.svg';
import { AlertSubscription } from 'types/alertSubscription';
import { useQueryWithError } from 'hooks/useQueryWithError';
import { alertSubscriptionsQueryKeys } from 'enums/AlertSubscriptionsQueryKeys.enum';
import {
  deleteMyAlertSubscription,
  fetchMyAlertSubscription,
} from 'services/API/me';
import { ALERT_SUBSCRIPTIONS_NOT_SAME } from 'utils/constants';
import { formatDistanceStrict } from 'date-fns';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface AlertSubscriptionControlProps {
  machinePartId: string | undefined;
}

type AlertSubscriptionControlState = 'ALERT_SUBSCRIPTIONS_NOT_SAME';

export const AlertSubscriptionControl = ({
  machinePartId,
}: AlertSubscriptionControlProps) => {
  const [isRequestSubscribe, setIsRequestSubscribe] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: deleteAlertSubscription } = useMutation<void, Error, string>({
    mutationFn: deleteMyAlertSubscription,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: alertSubscriptionsQueryKeys.all,
      });
    },
  });

  const { data, isPending } = useQueryWithError<
    AlertSubscription | AlertSubscriptionControlState
  >({
    queryKey: alertSubscriptionsQueryKeys.one(machinePartId || ''),
    queryFn: () => fetchMyAlertSubscription(machinePartId || ''),
    enabled: !!machinePartId,
    retry: 0,
  });

  if (!machinePartId) {
    return null;
  }

  const isSubscribed = !isPending && !!data;

  if (isSubscribed) {
    return (
      <Popover.Root>
        <Popover.Trigger>
          <Button variant="soft" color="gray" size="2">
            <BellIconFilled /> {t('activated')}
          </Button>
        </Popover.Trigger>
        <Popover.Content maxWidth="310px">
          {data === ALERT_SUBSCRIPTIONS_NOT_SAME ? (
            <Callout.Root variant="soft" size="1" color="blue">
              <Callout.Icon>
                <InfoCircledIcon />
              </Callout.Icon>
              <Callout.Text>
                {t('alertSubscriptionManagedUnderlying')}
              </Callout.Text>
            </Callout.Root>
          ) : (
            <>
              <Flex justify="between" align="center" mb="2">
                <Heading size="2">
                  {t('alertSubscriptions', { count: 1 })}
                </Heading>
                <AlertDialog.Root>
                  <AlertDialog.Trigger>
                    <Button variant="soft" color="red" size="1">
                      <MinusCircledIcon /> {t('deactivate')}
                    </Button>
                  </AlertDialog.Trigger>
                  <AlertDialog.Content maxWidth="450px">
                    <AlertDialog.Title>
                      {t('alertSubscriptionsDialog.deactivateTitle')}
                    </AlertDialog.Title>
                    <AlertDialog.Description size="2">
                      {t('alertSubscriptionsDialog.deactivateDescription')}
                    </AlertDialog.Description>

                    <Flex gap="3" mt="4" justify="end">
                      <AlertDialog.Cancel>
                        <Button variant="soft" color="gray">
                          {t('forms.cancel')}
                        </Button>
                      </AlertDialog.Cancel>
                      <AlertDialog.Action>
                        <Button
                          variant="solid"
                          color="red"
                          onClick={() => deleteAlertSubscription(machinePartId)}
                        >
                          {t('deactivate')}
                        </Button>
                      </AlertDialog.Action>
                    </Flex>
                  </AlertDialog.Content>
                </AlertDialog.Root>
              </Flex>
              <Callout.Root variant="soft" size="1" color="gray">
                <Callout.Text weight="medium">
                  <Trans
                    i18nKey="alertSubscriptionDisplay"
                    components={{
                      BadgeThreshold: <Badge size="2" color="red" />,
                      BadgeGracePeriod: <Badge size="2" color="blue" />,
                    }}
                    values={{
                      gracePeriod: formatDistanceStrict(
                        new Date(0),
                        new Date(data.grace_period * 1000)
                      ),
                      threshold: data.threshold * 100,
                    }}
                  />
                </Callout.Text>
              </Callout.Root>
            </>
          )}
        </Popover.Content>
      </Popover.Root>
    );
  }

  // Skeleton exposed as rendering case, since the popover doesn't apply onClick behavior to skeletons
  if (isPending) {
    return (
      <Skeleton loading={isPending}>
        <Button variant="outline" color="gray" size="2">
          <BellIcon /> {t('activate')}
        </Button>
      </Skeleton>
    );
  }

  return (
    <Popover.Root
      open={isRequestSubscribe}
      onOpenChange={() => setIsRequestSubscribe(!isRequestSubscribe)}
    >
      <Popover.Trigger>
        <Button variant="outline" color="gray" size="2">
          <BellIcon /> {t('activate')}
        </Button>
      </Popover.Trigger>
      <Popover.Content>
        <AlertSubscriptionForm
          onSuccess={() => setIsRequestSubscribe(false)}
          machinePartId={machinePartId}
        />
      </Popover.Content>
    </Popover.Root>
  );
};
