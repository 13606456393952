export enum AppRoutes {
  Root = '/',
  Login = '/login',
  SignUp = '/sign-up',
  Dashboard = '/dashboard',
  Detail = '/detail/:id',
  Model = '/model/:id',
  Sensor = '/sensor/',
  ModelsList = '/models',
  SensorsList = '/sensors',
  Administration = '/administration',
  AlertSubscriptionsList = '/alert-subscriptions',
}
