import React, { useState } from 'react';
import {
  AlertDialog,
  Button,
  Flex,
  RadioCards,
  Text,
  TextField,
  Tooltip,
} from '@radix-ui/themes';
import { ErrorMessage } from 'components/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import {
  AlertSubscription,
  AlertSubscriptionFormData,
  GeneralAlertSubscriptionConfig,
} from 'types/alertSubscription';
import { createMyAlertSubscription } from 'services/API/me';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { MachinePartSelect } from 'components/MachinePartSelect';
import { alertSubscriptionsQueryKeys } from 'enums/AlertSubscriptionsQueryKeys.enum';

const GracePeriodMap = {
  10: 600,
  15: 900,
  20: 1200,
  30: 1800,
  60: 3600,
};

interface AlertSubscriptionFormProps {
  machinePartId?: string;
  onSuccess: (alertSubscription: AlertSubscription) => void;
}

export const AlertSubscriptionForm = ({
  machinePartId,
  onSuccess,
}: AlertSubscriptionFormProps) => {
  const [isRequestForceMode, setIsRequestForceMode] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const AlertSubscriptionSchema = z.object({
    machinePartId: z.string(),
    grace_period: z.coerce
      .number()
      .min(Object.values(GracePeriodMap)[0])
      .max(Object.values(GracePeriodMap)[4]),
    threshold: z.coerce
      .number()
      .min(1)
      .max(100)
      .transform((val) => val / 100),
  });

  const { mutate: sendCreateMyAlertSubscription } = useMutation<
    AlertSubscription,
    AxiosError,
    AlertSubscriptionFormData
  >({
    mutationFn: createMyAlertSubscription,
    onSuccess(alertSubscription) {
      onSuccess(alertSubscription);
      queryClient.invalidateQueries({
        queryKey: alertSubscriptionsQueryKeys.all,
      });
    },
    onError(error) {
      if (error?.response?.status === 409) {
        setIsRequestForceMode(true);
      } else {
        toast.error('Error creating Alert Subscription');
      }
    },
  });

  const {
    register,
    handleSubmit: validate,
    control,
    formState: { errors },
  } = useForm<GeneralAlertSubscriptionConfig>({
    mode: 'onChange',
    resolver: zodResolver(AlertSubscriptionSchema),
    defaultValues: {
      threshold: 30,
      grace_period: 900,
      machinePartId,
    },
  });

  const handleSubmit = (e: React.FormEvent | undefined, forceMode = false) => {
    validate(({ machinePartId, ...alertConfig }) => {
      sendCreateMyAlertSubscription({
        alert_config: alertConfig,
        machinePartId: machinePartId || '',
        ...(forceMode ? { force_mode: true } : {}),
      });
    })(e);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {!machinePartId && (
          <MachinePartSelect
            control={control}
            name="machinePartId"
            error={errors.machinePartId}
          />
        )}
        <Flex justify="between">
          <Tooltip content={t('gracePeriodDescription')}>
            <label htmlFor="gracePeriod">
              <Text as="div" size="2" weight="bold">
                {t('gracePeriod')} ({t('minutes', { count: 2 })})
              </Text>
            </label>
          </Tooltip>
          <ErrorMessage error={errors.grace_period} />
        </Flex>
        <Controller
          control={control}
          name="grace_period"
          render={({ field }) => (
            <RadioCards.Root
              onValueChange={field.onChange}
              {...field}
              value={field.value.toString()}
              id="grace_period"
              columns="5"
              gap="1"
              size="1"
              mt="1"
              mb="2"
            >
              {Object.entries(GracePeriodMap).map(([key, value]) => (
                <RadioCards.Item value={value.toString()} key={key}>
                  {key}
                </RadioCards.Item>
              ))}
            </RadioCards.Root>
          )}
        />
        <Flex justify="between">
          <Tooltip content={t('thresholdDescription')}>
            <label htmlFor="threshold">
              <Text as="div" size="2" weight="bold">
                {t('threshold')}
              </Text>
            </label>
          </Tooltip>
          <ErrorMessage error={errors.threshold} />
        </Flex>
        <TextField.Root
          id="threshold"
          type="number"
          mt="1"
          mb="2"
          {...register('threshold')}
        >
          <TextField.Slot />
          <TextField.Slot>%</TextField.Slot>
        </TextField.Root>
        <Button type="submit" mt="2">
          {t('activate')}
        </Button>
      </form>
      <AlertDialog.Root
        open={isRequestForceMode}
        onOpenChange={() => setIsRequestForceMode(!isRequestForceMode)}
      >
        <AlertDialog.Content maxWidth="450px">
          <AlertDialog.Title>Override Alert subscriptions</AlertDialog.Title>
          <AlertDialog.Description size="2">
            Continuing will override the Alert subscriptions for all Models of
            this machine part
          </AlertDialog.Description>
          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Cancel>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action>
              <Button
                variant="solid"
                color="red"
                onClick={() => handleSubmit(undefined, true)}
              >
                Force Subscribe
              </Button>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </>
  );
};
