import { api } from 'services/API/client';
import {
  AlertSubscription,
  AlertSubscriptionFormData,
} from 'types/alertSubscription';

export const fetchAllMyAlertSubscriptions = async (): Promise<
  AlertSubscription[]
> => {
  const {
    data: { data },
  } = await api.get('/me/alert-subscriptions');

  return data;
};

type AlertSubscriptionControlState = 'ALERT_SUBSCRIPTIONS_NOT_SAME';

export const fetchMyAlertSubscription = async (
  id: string
): Promise<AlertSubscription | AlertSubscriptionControlState> => {
  const {
    data: { data = null },
  } = await api.get(`/me/machines/${id}/alert-subscriptions`);

  return data;
};

export const createMyAlertSubscription = async ({
  machinePartId,
  ...payload
}: AlertSubscriptionFormData): Promise<AlertSubscription> => {
  const {
    data: { data },
  } = await api.post(
    `/me/machines/${machinePartId}/alert-subscriptions`,
    payload
  );

  return data;
};

export const deleteMyAlertSubscription = async (id: string): Promise<void> => {
  await api.delete(`/me/machines/${id}/alert-subscriptions`);
};
