import React from 'react';
import { Flex, Select, Text } from '@radix-ui/themes';
import { MachineTree } from 'types/machine';
import { useMachineTree } from 'hooks/useMachineTree/useMachineTree';
import { Controller, Control, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from 'components/ErrorMessage';

const renderSelectComponents = ({ id, label, children }: MachineTree) => (
  <>
    <Select.Item value={id}>{label}</Select.Item>
    <Select.Group>
      {children.map((child) => {
        const component = (
          <Select.Item value={child.id} key={child.id}>
            {child.label}
          </Select.Item>
        );
        renderSelectComponents(child);
        return component;
      })}
      <Select.Separator />
    </Select.Group>
  </>
);

const renderSelectMachines = ({ children, id, label }: MachineTree) => (
  <Select.Group key={id}>
    <Select.Label>{label}</Select.Label>
    {children.map(renderSelectComponents)}
  </Select.Group>
);

interface MachinePartSelectProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, unknown>;
  name: string;
  error: FieldError | undefined;
}

export const MachinePartSelect = ({
  control,
  name,
  error,
}: MachinePartSelectProps) => {
  const { data: allParts } = useMachineTree();
  const { t } = useTranslation();

  return (
    <>
      <Flex justify="between">
        <label htmlFor={name}>
          <Text as="div" size="2" weight="bold">
            {t('selectComponent.label')}
          </Text>
        </label>
        <ErrorMessage error={error} />
      </Flex>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select.Root
            size="3"
            onValueChange={field.onChange}
            {...field}
            value={field.value as string}
          >
            <Select.Trigger
              id="eventPart"
              mb="3"
              placeholder={t('selectComponent.placeholder')}
            />
            <Select.Content position="popper" id={name}>
              {allParts?.map(renderSelectMachines)}
            </Select.Content>
          </Select.Root>
        )}
      />
    </>
  );
};
