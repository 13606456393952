import {
  AlertDialog,
  Button,
  Flex,
  Heading,
  Spinner,
  Text,
  Table,
  Dialog,
  Badge,
} from '@radix-ui/themes';
import React, { useState } from 'react';
import {
  deleteMyAlertSubscription,
  fetchAllMyAlertSubscriptions,
} from 'services/API/me';
import { useQueryWithError } from 'hooks/useQueryWithError';
import { alertSubscriptionsQueryKeys } from 'enums/AlertSubscriptionsQueryKeys.enum';
import { AlertSubscription } from 'types/alertSubscription';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PlusCircledIcon,
} from '@radix-ui/react-icons';
import * as Collapsible from '@radix-ui/react-collapsible';
import { AlertSubscriptionForm } from 'components/AlertSubscriptionForm';
import { formatDistanceStrict } from 'date-fns';

export const AlertSubscriptions = () => {
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [isOpenedItems, setIsOpenedItems] = useState<string[]>([]);
  const { isPending, data } = useQueryWithError<AlertSubscription[]>({
    queryKey: alertSubscriptionsQueryKeys.all,
    queryFn: fetchAllMyAlertSubscriptions,
    retry: 0,
  });
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: deleteAlertSubscription } = useMutation<void, Error, string>({
    mutationFn: deleteMyAlertSubscription,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: alertSubscriptionsQueryKeys.all,
      });
    },
  });

  if (isPending) {
    return <Spinner />;
  }

  return (
    <div>
      <Flex justify="between" align="center" mb="3">
        <Heading>{t('alertSubscriptions', { count: 2 })}</Heading>
        <Dialog.Root
          onOpenChange={() => setIsAddingItem(!isAddingItem)}
          open={isAddingItem}
        >
          <Dialog.Trigger>
            <Button>
              <PlusCircledIcon />
              {t('forms.create')}
            </Button>
          </Dialog.Trigger>
          <Dialog.Content maxWidth="370px" size="2">
            <Dialog.Title>{t('alertSubscriptionCreateTitle')}</Dialog.Title>
            <Dialog.Description>
              <AlertSubscriptionForm onSuccess={() => setIsAddingItem(false)} />
            </Dialog.Description>
          </Dialog.Content>
        </Dialog.Root>
      </Flex>
      <Table.Root variant="surface">
        <Table.Row>
          <Table.ColumnHeaderCell>Component</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Threshold</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Grace Period</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell />
        </Table.Row>
        {data?.map(
          ({
            machine_part: { id, label },
            models,
            threshold,
            grace_period: gracePeriod,
          }) => (
            <Collapsible.Root
              key={id}
              asChild
              open={isOpenedItems.includes(id)}
              onOpenChange={() => {
                if (isOpenedItems.includes(id)) {
                  setIsOpenedItems(isOpenedItems.filter((item) => item !== id));
                } else {
                  setIsOpenedItems([...isOpenedItems, id]);
                }
              }}
            >
              <>
                <Table.Row>
                  <Table.RowHeaderCell>
                    <Text weight="medium">{label}</Text>
                  </Table.RowHeaderCell>
                  <Table.Cell>
                    <Badge size="2" color="red">
                      {threshold * 100}%
                    </Badge>
                  </Table.Cell>
                  <Table.Cell>
                    <Badge size="2" color="blue">
                      <>
                        {formatDistanceStrict(
                          new Date(0),
                          new Date(gracePeriod * 1000)
                        )}
                      </>
                    </Badge>
                  </Table.Cell>
                  <Table.Cell>
                    <Flex align="center" justify="end" gap="3">
                      <Collapsible.Trigger asChild>
                        <Button size="1" variant="ghost">
                          {isOpenedItems.includes(id) ? (
                            <>
                              <ChevronUpIcon />
                              {t('hideModels')}
                            </>
                          ) : (
                            <>
                              <ChevronDownIcon /> {t('showModels')}
                            </>
                          )}
                        </Button>
                      </Collapsible.Trigger>
                      <AlertDialog.Root>
                        <AlertDialog.Trigger>
                          <Button variant="soft" color="red" size="1">
                            {t('deactivate')}
                          </Button>
                        </AlertDialog.Trigger>
                        <AlertDialog.Content maxWidth="450px">
                          <AlertDialog.Title>
                            {t('alertSubscriptionsDialog.deactivateTitle')}
                          </AlertDialog.Title>
                          <AlertDialog.Description size="2">
                            {t(
                              'alertSubscriptionsDialog.deactivateDescription'
                            )}
                          </AlertDialog.Description>

                          <Flex gap="3" mt="4" justify="end">
                            <AlertDialog.Cancel>
                              <Button variant="soft" color="gray">
                                {t('forms.cancel')}
                              </Button>
                            </AlertDialog.Cancel>
                            <AlertDialog.Action>
                              <Button
                                variant="solid"
                                color="red"
                                onClick={() => deleteAlertSubscription(id)}
                              >
                                {t('deactivate')}
                              </Button>
                            </AlertDialog.Action>
                          </Flex>
                        </AlertDialog.Content>
                      </AlertDialog.Root>
                    </Flex>
                  </Table.Cell>
                </Table.Row>
                <Collapsible.Content asChild>
                  <>
                    {models.map(({ id, label }) => (
                      <Table.Row key={id}>
                        <Table.Cell colSpan={4}>{label}</Table.Cell>
                      </Table.Row>
                    ))}
                  </>
                </Collapsible.Content>
              </>
            </Collapsible.Root>
          )
        )}
      </Table.Root>
    </div>
  );
};
